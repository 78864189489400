import { useEffect, useState } from "react";
import UserPrompt from "./UserPrompt";

const Address = ({customerId, onClose}) => {
    const [updatedAddress, setAddress] = useState();
    const[message, setMessage] = useState("");
    const[failed, setFailed] = useState(false);

    useEffect(() => {
      const fetchdata = async () => {
        const response = await fetch(`${process.env.REACT_APP_BASE_API}/address/customer/${customerId}`);
        const data = await response.json();
        setAddress(data);
      }

      fetchdata();
    }, []);

    const handleAddressChange = (e) => {
        const{name, value} = e.target;
        setAddress((prev) => {
          return { ...prev, [name]:value };
        });
      }

      const handleClose = (e) =>{
        e.preventDefault();
        onClose();
      }

    const handleSave = async (e) => {
        e.preventDefault();
        setMessage('');
        setFailed(false);
        const response = await fetch(`${process.env.REACT_APP_BASE_API}/address/upsert`, {
          method: "POST",
          headers:{
              'Content-Type': 'application/json',
          },
          body:JSON.stringify(updatedAddress)
        });
    
        const data = await response.json();    
        if(data.success){
          setMessage("Address Info saved successfully.");
        }
        else{
          setFailed(true);
          setMessage("Unable to save.  Check your values!");
        }
    }

    return (
        <form onSubmit={handleSave}>
            <div className="flex flex-col gap-3">
            <div className="flex flex-col">
            <label>Address One:</label>
            <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                type="text" name="addressOne" value={updatedAddress?.addressOne} onChange={handleAddressChange} />
            </div>   
            <div className="flex flex-col">
            <label>Address Two:</label>
            <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                type="text" name="addressTwo" value={updatedAddress?.addressTwo} onChange={handleAddressChange} />
            </div>         
            <div className="flex flex-col">
            <label>City:</label>
            <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                type="text" name="city" value={updatedAddress?.city} onChange={handleAddressChange} />
            </div>  
            <div className="flex flex-col">
            <label>Province:</label>
            <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                type="text" name="provinceState" value={updatedAddress?.provinceState} onChange={handleAddressChange} />
            </div>
            <div className="flex flex-col">
            <label>Postal Code:</label>
            <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                type="text" name="postalCodeZip" value={updatedAddress?.postalCodeZip} onChange={handleAddressChange} />
            </div>
            <div className="flex justify-between gap-5">
            <div>
                    {
                      message.length > 0 &&
                      <UserPrompt message={message} failed={failed} />
                    }
                  </div>
                    <div className="gap-5 flex">
                    <button className="bg-red-900 text-white py-2 px-4 rounded-md text-sm font-light" onClick={handleClose}>Close</button>
                    <button className="bg-sky-900 text-white py-2 px-4 rounded-md text-sm font-light" type="submit">Save</button>
                    </div>
            </div> 
        </div> 
        </form>
  );
}

export default Address;
