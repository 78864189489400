import React, {useEffect, useState}  from "react"; 
import Plot from 'react-plotly.js';
import { NumericFormat } from 'react-number-format';

const Last4Stats = () => {
    const[stats, setStats] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
           const response = await fetch(`${process.env.REACT_APP_BASE_API}/inboundinvoice/last4stats`);
           const data = await response.json();
           setStats(data);
        }

        fetchData();

    }, []);

    const clientCosts = stats.map(item => item.clientCost);
    const upsCosts = stats.map(item => item.upsCost);
    const importedDates = stats.map(item => item.importedDateStr);

    return(
        <div className="flex gap-10 w-full justify-around bg-sky-100 p-2 rounded-lg">
            <div className="rounded-lg flex justify-center items-center">
                <Plot
                    data={[
                        {
                            x: importedDates,
                            y: upsCosts,
                            type: 'bar',
                            name: 'Ups Cost',
                            text: upsCosts.map(cost => `$${cost}`),
                            textposition: 'auto',
                        },
                        {
                            x: importedDates,
                            y: clientCosts,
                            type: 'bar',
                            name: 'Client Cost',
                            text: clientCosts.map(cost => `$${cost}`),
                            textposition: 'auto',
                        }
                    ]}
                    layout={ 
                        {
                            width: 600, 
                            height: 400, 
                            title: 'Last Four Invoices Stats', 
                            legend: {
                                x: 1.0,
                                y: 0.2,
                                bgcolor: 'rgba(255, 255, 255, 0.7)',
                                bordercolor: '#ccc',
                                borderwidth: 1,
                            },
                            margin: {
                                l: 50,
                                r: 50,
                                b: 50,
                                t: 50,
                                pad: 4,
                            },
                            yaxis:{
                                range: [0, Math.max(...upsCosts, ...clientCosts) * 1.1] 
                            },
                            xaxis: {
                                type:'category',
                                tickformat: "%Y-%m-%d",
                              }
                        }
                     }
                />
            </div>
        </div>
    );
}

export default Last4Stats;