import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';

const InvoiceLogs = ({importedDate}) => {
    const[logs, setLogs] = useState([]);

    useEffect(() => {
        var formData = {
            importedDate: importedDate
        }
        const fetchData = async () => {
            const response = await fetch(`${process.env.REACT_APP_BASE_API}/inboundinvoice/getlogs`, {
                method: "POST",
                headers:{
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify(formData)
            });

            const data = await response.json();
            setLogs(data);
        }

        fetchData();
    }, []);

    const columns = [
        {field: 'trackingId', headerName:'Tracking ID', width:200},
        {field:'billToCompany', headerName:'Bill To Company', width:100},
        {field: 'customerNumber', headerName:'Customer Number', width:100},
        {field: 'accountNumber', headerName:'Account Number', width:100},
        {field: 'chargeCategory', headerName:'Category', width:100},
        {field: 'chargeDescription', headerName:'Description', width:100},
        {field: 'senderCompany', headerName:'Sent By', width:100},
        {field: 'receiverCompany', headerName:'Received By', width:100},
        {field: 'mainReferenceNumber', headerName:'Reference', width:100},
        {field: 'upsCharged', headerName:'UPS Charge', width:100},
    ];

    
    return (
        <div>
             <DataGrid 
                rows={logs} 
                columns={columns} 
                disableColumnSelector/>
        </div>
    );
}

export default InvoiceLogs;
