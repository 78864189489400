import { useState, useEffect, useCallback, useMemo } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Close, Pencil, Trash } from "../assets";
import { Dialog, DialogContent, DialogTitle, FormControlLabel, RadioGroup, Radio } from "@mui/material";
import Address from "./Address";
import Discount from "./Discount";
import UserPrompt from "./UserPrompt";
import Accessorial from "./Accessorial";

const Clients = () => {
  const [clients, setClients] = useState([]);
  const[openCustomer, setOpenCustomer] = useState(false);
  const[customer, setCustomer] = useState({});
  const[message, setMessage] = useState("");
  const[failed, setFailed] = useState(false);
  const[reload, setReload] = useState(false);

  const[openAddress, setOpenAddress] = useState(false);
  //const[address, setAddress] = useState();

  const[openDiscount, setOpenDiscount] = useState(false);
  const[openAccessorial, setOpenAccessorial] = useState(false);

  const apiUrl = useMemo(() => `${process.env.REACT_APP_BASE_API}/customer/customers`, []);

  const fetchData = useCallback(async () => {
    const response = await fetch(apiUrl);
    const data = await response.json();
    setClients(data);
  }, [apiUrl]);

  useEffect(() => {
    fetchData();
  }, [fetchData, reload]);

  const columns = [
    {field: 'customerNumber', headerName:'Account Number', width:300},
    {field:'companyName', headerName:'Bill To Company', width:500},
    {
        field:'edit',
        headerName:'Action',
        width:400,
        sortable: false, 
        filterable: false,
        disableColumnMenu:true,
        renderCell:(params) => (
            <div className="cursor-pointer flex text-sky-900 gap-x-4">
              <div><Pencil onClick={() => handleCustomerEdit(params.row)}/></div>
              <div><Trash onClick={() => handleDelete(params.row)} /></div>   
              <div>
                  <button onClick={() => handleAddresDialog(params.row)} className="bg-sky-900 text-white py-1 px-2 rounded-md text-sm font-light ">Address</button>
              </div>     
              <div>
                <button onClick={() => handleDiscountDialog(params.row)} className="bg-red-900 text-white py-1 px-2 rounded-md text-sm font-light ">Discount</button>
              </div>
              <div>
                <button onClick={() => handleAccessorialDialog(params.row)} className="bg-red-900 text-white py-1 px-2 rounded-md text-sm font-light ">Accessorial</button>
              </div>
            </div>
        )
    }
  ]

  const handleDelete = async (row) => {
    const response = await fetch(`${process.env.REACT_APP_BASE_API}/customer/delete/${row.id}`, {
      method:'DELETE'
    });

    const data = await response.json();
    if(data.success){
      setMessage("Customer has been deleted successfully.");
      setReload(true);
    }
    else{
      setFailed(true);
      setMessage("Error on deleting customer!");
    }
  }

  const handleCustomerEdit = (row) => {    
    setCustomer(row);
    setOpenCustomer(true);
  }

  const handleClose = (e) =>{
    e.preventDefault();
    setOpenCustomer(false);
    setMessage("");
    setFailed(false);
  }

  const handleAddresDialog = (customer) => {
    setCustomer(customer);
    setOpenAddress(true);
  }

  const handleInputChange = (e) => {
    const { name, type, checked, value } = e.target;
    setCustomer((prev) => {
      if (type === 'checkbox') {
        return { ...prev, [name]: checked};
      } else {
          return { ...prev, [name]: value };
      }
    });
  }

  const handleCustomerSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setFailed(false);
    const response = await fetch(`${process.env.REACT_APP_BASE_API}/customer/upsert`, {
      method: "POST",
      headers:{
          'Content-Type': 'application/json',
      },
      body:JSON.stringify(customer)
    });

    const data = await response.json();    
    if(data.success){
      setMessage("Customer Info saved successfully.");
      setReload(true);
    }
    else{
      setFailed(true);
      setMessage("Unable to save.  Check your values!");
    }
  }

  const handleDiscountDialog = (customer) => {
    setCustomer(customer);
    setOpenDiscount(true);
  }

  const addNewCustomer = () => {
    setCustomer({});
    setOpenCustomer(true);
  }

  const handleAccessorialDialog = (customer) => {
    setCustomer(customer);
    setOpenAccessorial(true);    
  }

  return (
    <div>
      <div>
        <button className="bg-sky-900 text-white py-2 px-4 rounded-md text-sm font-light" type="submit" onClick={() => addNewCustomer(true)}>Add New Client</button>
      </div>
      <Dialog fullWidth="lg" open={openAddress}>
        <DialogTitle>Customer Address</DialogTitle>
        <DialogContent>
          <Address onClose={() => setOpenAddress(false)} customerId={customer.id} />
        </DialogContent>
      </Dialog>
      <Dialog fullWidth="lg" maxWidth="sm" open={openCustomer}>
        <DialogTitle>Customer</DialogTitle>
        <DialogContent>
          <form onSubmit={handleCustomerSubmit}>
            <div className="flex flex-col gap-1">
                <div className="flex flex-col">
                  <label>Company Name (Bill To Name):</label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    type="text" name="companyName" value={customer?.companyName} onChange={handleInputChange} />
                </div>   
                <div className="flex flex-col">
                  <label>Account Number:</label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    type="text" name="customerNumber" value={customer?.customerNumber} onChange={handleInputChange}  />
                </div>  
                <div className="flex flex-col">
                  <label>Additional Account Number(s) <span className="text-sm">[Use comma to separate if more than one]</span>:</label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    type="text" name="additionalAccountNumbers" value={customer?.additionalAccountNumbers} onChange={handleInputChange}  />
                </div>          
                <div className="flex flex-col">
                  <label>UPS Used Company Name(s) <span className="text-sm">[Use comma to separate if more than one]</span>:</label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    type="text" name="upsCompanyNames" value={customer?.upsCompanyNames || ''} onChange={handleInputChange}  />
                </div>  
                <div className="flex flex-col">
                  <label>Email:</label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    type="text" name="emailOne" value={customer?.emailOne} onChange={handleInputChange}  />
                </div>  
                <div className="flex flex-col">
                  <label>Phone Number:</label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    type="text" name="phoneOne" value={customer?.phoneOne} onChange={handleInputChange}  />
                </div>  
                <div className="flex flex-col gap-1">
                  <div className="flex gap-2 items-center">                  
                    <input 
                      type="checkbox" name="applyFlatRate" 
                      className="w-4 h-4 border-gray-300 rounded-lg accent-sky-900"
                      checked={customer?.applyFlatRate} onChange={handleInputChange} />
                      <label>Apply Flat Rate</label>
                  </div>  
                  <div className="flex gap-2 items-center">                  
                    <input 
                      type="checkbox" name="applyAccessorial" 
                      className="w-4 h-4 border-gray-300 rounded-lg accent-sky-900"
                      checked={customer?.applyAccessorial} onChange={handleInputChange} />
                      <label>Apply Accessorial Markdown</label>
                  </div>  
                  <div className="flex gap-2 items-center">                  
                    <input 
                      type="checkbox" name="applyDeclaredValueDiscount" 
                      className="w-4 h-4 border-gray-300 rounded-lg accent-sky-900"
                      checked={customer?.applyDeclaredValueDiscount} onChange={handleInputChange} />
                      <label>Apply Declared Value Discount</label>
                  </div>
                </div>
               
                <div className="flex justify-between">
                  <div>
                    {
                      message.length > 0 &&
                      <UserPrompt message={message} failed={failed} />
                    }
                  </div>
                  <div className="flex justify-end gap-5">
                    <button className="bg-red-900 text-white py-2 px-4 rounded-md text-sm font-light" onClick={handleClose}>Close</button>
                    <button className="bg-sky-900 text-white py-2 px-4 rounded-md text-sm font-light" type="submit" >Save</button>
                  </div> 
                </div>
            </div> 
          </form>   
        </DialogContent>
      </Dialog>
      <Dialog fullWidth={true} maxWidth="lg" open={openDiscount}>
        <DialogContent>
          <Discount id={customer?.id} onClose={() => setOpenDiscount(false)} />
        </DialogContent>
      </Dialog>
      <Dialog fullWidth={true} maxWidth="md" open={openAccessorial}>
        <DialogContent>
          <Accessorial id={customer?.id} onClose={() => setOpenAccessorial(false)} />
        </DialogContent>
      </Dialog>
      <DataGrid 
        rows={clients} 
        columns={columns} 
        disableColumnSelector/>
    </div>
  );
};

export default Clients;